<template>
	<page>
		<module enable-flex>
			<m-search ref="search" @search="searchHandle">
				<m-search-item label="账号名">
					<c-input name="user_name"></c-input>
				</m-search-item>
				<m-search-item label="姓名">
					<c-input name="user_realname"></c-input>
				</m-search-item>
				<m-search-item label="手机号">
					<c-input name="phone"></c-input>
				</m-search-item>
				<m-search-item label="状态">
					<c-checkbox-group name="state" :options="userState"></c-checkbox-group>
				</m-search-item>
			</m-search>

			<m-operate>
				<c-button v-if="auth('b_user_edit')" color="sys" @click="createUser">新建账号</c-button>
			</m-operate>

			<c-table ref="table" height="grow">

				<c-table-column
					label="账号名"
					width="120"
					name="user_name"
				></c-table-column>

				<c-table-column
					label="姓名"
					width="120"
					name="user_realname"
				></c-table-column>

				<c-table-column
					label="手机号"
					width="150"
					name="phone"
				></c-table-column>
				
				<c-table-column
					label="角色"
					width="170"
					name="roles"
				>
					<template #default="{data}">
						<span v-if="data.roles&&data.roles!=''">{{data.roles}}</span>
						<span v-else>--</span>
					</template>
				</c-table-column>

				<c-table-column
					label="状态"
					width="90"
					name="state"
				>
					<template v-slot="{data}">
						<span v-if="data.user_type == 1">--</span>
						<c-switch v-if="data.user_type == 2" :value="data.state" :state-value="[2,1]" :state-text="['启用','禁用']"
							  @change="changeState"
							  @beforeChange="beforeChangeState(arguments[0], arguments[1], data)"
						></c-switch>
					</template>
				</c-table-column>

				<c-table-column
					label="创建时间"
					width="140"
					name="create_date"
				></c-table-column>

				<c-table-column
					type="button"
					label="操作"
					width="260"
				>
				  <template v-slot="{data}">
						<c-table-button v-if="auth('b_user_edit')" @click="editUser(data)">编辑</c-table-button>
						<c-table-button v-if="auth('b_user_auth')" @click="roleConfig(data)">角色配置</c-table-button>
						<c-table-button v-if="auth('b_user_reset_pwd')" @click="resetPwd(data)">重置密码</c-table-button>
						<c-table-button v-if="auth('b_user_edit')" @click="deleteUser(data)">删除</c-table-button>
				  </template>
				</c-table-column>
			</c-table>
		</module>

		<c-dialog ref="userEdit" :title="editState ? '编辑账号' : '新建账号'" width="600" @resolve="submitUser">
			<c-form ref="userForm" unit-width="100%">
				<c-form-item v-if="editState" label="账号名称" required>
					<c-input name="user_name" readonly disabled></c-input>
				</c-form-item>
				<c-form-item v-if="!editState" label="账号名称" required tip="支持中文、英文、数字、下划线及横线，区分大小写">
					<c-input name="user_name" required maxlength="15"></c-input>
				</c-form-item>
				<c-form-item v-if="!editState" label="登录密码" required tip="密码不能使用特殊字符，区分大小写">
					<c-input name="user_pwd" type="password" required maxlength="15"></c-input>
				</c-form-item>
				<c-form-item label="真实姓名" required>
					<c-input name="user_realname" required maxlength="15"></c-input>
				</c-form-item>
				<c-form-item label="手机号码">
					<c-input name="phone" required maxlength="11"></c-input>
				</c-form-item>
				<c-form-item label="是否启用" required>
					<c-radio-group  name="state" :options="userState"></c-radio-group>
				</c-form-item>
				<c-input type="hidden" name="id"></c-input>
			</c-form>
		</c-dialog>

    <role-selector ref="roleSelector" multiple @resolve="submitRoleConfig"></role-selector>

  </page>
</template>

<script>
import roleSelector from '@/components/service/role_selector.vue'
import {cryptoJs} from '@deanwu/vue-component-library'
import {mapState} from 'vuex'

export default {
	name: 'm_user',
	components: {
		roleSelector
	},
	data() {
		return {
			userState: [{
				name: '启用',
				value: 2
			}, {
				name: '禁用',
				value: 1
			}],
			teamOptions:[],
			editState: false
		}
	},
	computed: {
		
	},
	mounted() {
        this.$refs.search.search();
	},
	methods: {
		tabHandle(item) {
		  this.$refs.search.search();
		},
		searchHandle(data) {
			data.user_type = 2;
			this.$refs.table.load({
				url: '/sys_user/list',
				data
			});
		},
		//启用禁用前 回调函数
		beforeChangeState(state,next,data){
			// 确认弹窗
			this.$confirm({
				title: (state?'禁用':'启用')+'账号',
				message: '是否确定【'+(state?'禁用':'启用')+'】账号【'+data.user_name+'】？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
				  this.request({
					url: '/sys_user/update_state',
					data: {
					  id:data.id,
					  state:state?1:2
					},
					loading: true,
					success: data => {
					  //远程接口调用完成，执行next方法，变更开关状态（执行失败则不变更状态）
					  next();
					}
				  });
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//启用禁用 完成回调函数
		changeState(state){
			// 消息弹窗
			this.$message({
				message: (state?'启用':'禁用')+'成功',  // 提示内容,
				type: 'success'
			});
		},
		//创建账号
		createUser() {
			this.editState = false;
			this.$refs.userForm.clear();
			this.$refs.userForm.set({});
			this.$refs.userEdit.open();
		},
		//编辑账号
		editUser(val) {
			this.editState = true;
			this.$refs.userForm.set(val);
			this.$refs.userEdit.open();
		},
		//删除账号
		deleteUser(val){
			this.$confirm({
				title: '确认',
				message: '确定要删除【'+val.user_name+'】吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/sys_user/delete',
						data: {user_id:val.id},
						loading: true,
						success: data => {
							this.$message({
							  message: '删除成功',
							  type: 'success'
							});
							this.$refs.table.update();
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//提交保存
		submitUser(stop) {
			stop();
			this.$refs.userForm.submit({
				url: this.editState ? '/sys_user/edit' : '/sys_user/add',
				rule: {
					user_name: [{
						type: 'empty',
						message: '请填写账号名',
						skip: () => {
						  return this.editState;
						}
					}],
					user_pwd: [{
						type: 'empty',
						message: '请填写登录密码',
						skip: () => {
						  return this.editState;
						}
					}, {
						type: 'regexp',
						message: '登录密码格式错误',
						regexp: ['password'],
						skip: () => {
						  return this.editState;
						}
					}],
					user_realname: '请填写真实姓名',
					state: '请选择启禁用状态'
				},
				dataFormatter: data => {
					data.team_id = data.team_id||0;
					data.user_pwd = cryptoJs.MD5(data.user_pwd).toString();
					data.user_type = 2;
					return data;
				},
				success: (data, res) => {
					this.$message({
						message: '保存成功',
						type: 'success'
					});
					this.$refs.table.update();
					this.$refs.userEdit.close();
				}
			});
		},
		//重置密码
		resetPwd(val) {
			this.$confirm({
				title: '重置登录密码',
				message: '确定要重置【'+val.user_name+'】的密码吗？',  // 提示内容,
				buttonName: '确定',  // 确定按钮文字
				cancelButton: true,  // 是否显示取消按钮
				resolve: () => {
					this.request({
						url: '/sys_user/reset_pwd',
						data: {id:val.id},
						loading: true,
						success: data => {
						  this.$confirm({
							title: '重置账号密码成功',
							message: data,  // 提示内容,
							buttonName: '知道了',  // 确定按钮文字
							cancelButton: false,  // 是否显示取消按钮
							resolve: () => {},  // 确定按钮回调
							cancel: () => {},  // 取消按钮回调
						  });
						}
					});
				},  // 确定按钮回调
				cancel: () => {},  // 取消按钮回调
			});
		},
		//设置角色-弹框
		roleConfig(data) {
			this._roleConfig = data;
			this.$refs.roleSelector.open(data.id);
		},
		//设置角色-提交
		submitRoleConfig(data) {
			this.request({
				url: '/sys_user/user_role_edit',
				data: {
					user_id: this._roleConfig.id,
					role_ids: Array.from(data, item => {
						return {
						  role_id: item.id
						}
					})
				},
				loading: true,
				success: () => {
				  this.$refs.table.update();
				}
			});
		}
	}
}
</script>